// Features tabs (core/sections/_features-tabs.scss) ---------
$features-tabs--padding-t__mobile: 		    null;		// features tabs, top padding (mobile)
$features-tabs--padding-b__mobile:			null;		// features tabs, bottom padding (mobile)
$features-tabs--padding-t__desktop: 		null;		// features tabs, top padding (desktop)
$features-tabs--padding-b__desktop: 		null;		// features tabs, bottom padding (desktop)
$features-tabs-header--padding__mobile: 	null;		// features tabs header, bottom padding (mobile)
$features-tabs-header--padding__desktop: 	null;		// features tabs header, bottom padding (desktop)
$features-tabs-list--padding-b:             48px;		// tabs list, bottom padding
$features-tabs-item--padding-h:             16px;		// tabs item, left and right padding
$features-tabs-item--padding-v:             10px;       // tabs item, top and bottom padding
$features-tabs-panel--padding-h:            0;          // tabs panel, left and right padding
$features-tabs-panel--padding-v:            0;          // tabs panel, top and bottom padding