.features-tiles-item-image {
	display: inline-flex;
	background-repeat: no-repeat;
	background-size: 100%;
	border-radius: 50%;

	&.primary-color {
		background-color: get-color(primary, 1);
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzYiIGhlaWdodD0iNjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMjQuOTUgMS45MjhBMzkuNjk1IDM5LjY5NSAwIDAgMSAyOC43Ni42YzYuODcyIDQuMDE5IDEzLjcwNCA4LjE1NSAyMC43NzEgMTIuMDE3IDguMTU1IDQuNDU4IDE2LjYyNyA4LjU0NCAyNS40NzQgMTEuNDg3Ljk3LjMyOS43MjYgMS4wNjMtLjI1Ni43NS0xNy41MTctNS40ODMtMzQuMzU0LTEzLjE4OC00OS44LTIyLjkyN3oiIG9wYWNpdHk9Ii4xNTgiLz48cGF0aCBkPSJNLjIxMyAzNC44NDdjLjE1LTEuNDYyLjM4LTIuOTAxLjY4NS00LjMxMiAyLjAxOCAzLjEwNSA0LjAzIDYuMjI2IDYuMSA5LjMyOSA0LjQ5OCA2Ljc0MSA5LjI3NSAxMy4zOTQgMTQuNzk5IDE5LjM3LjYwNC42NjEuMDE2IDEuMTgtLjYwNy41MjhDMTMuNjQgNTEuOTI1IDYuNjA4IDQzLjU5NS4yMTMgMzQuODQ3eiIgb3BhY2l0eT0iLjE1NSIvPjwvZz48L3N2Zz4=);
		box-shadow: 0 8px 24px rgba(get-color(primary, 1), .24);
	}

	&.secondary-color {
		background-color: get-color(secondary, 1);
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iODAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBvcGFjaXR5PSIuMDgiPjxwYXRoIGQ9Ik00MS44ODIuMDQzYy44OS4wNDIgMS43NzMuMTEyIDIuNjQ3LjIxLTguNDM0IDcuMTItMTcuMzM4IDEzLjY0Ni0yNi42MzYgMTkuNDUzLTIuOTM0IDEuODE1LTQuMTYyLS44MjItMS40OS0yLjY4MUMyMi45NzUgMTIuNDEzIDI5LjgxIDguMTg0IDM2LjU2IDMuNjhjMS43ODQtMS4xOSAzLjU2MS0yLjM5OSA1LjMyMS0zLjYzN3pNMzkuMjc0IDc5Ljk5NGE0NjcuNzUgNDY3Ljc1IDAgMCAwIDMuNTktMi4zN2M2Ljc0MS00LjQ5NiAxMy4zOTQtOS4yNzQgMTkuMzctMTQuNzk3LjY2MS0uNjA1IDEuMTgtLjAxNy41MjguNjA2LTYgNS43OC0xMi4yODkgMTEuMjU3LTE4LjgzMyAxNi4zNzZhNDAuNDgzIDQwLjQ4MyAwIDAgMS00LjY1NS4xODV6Ii8+PC9nPjwvc3ZnPg==);
		box-shadow: 0 8px 24px rgba(get-color(secondary, 1), .24);
	}
}
