.testimonial {

	.tiles-item-inner {
		background: get-color(light, 1);
	}
}

.testimonial-item-image {

	img {
		border-radius: 50%;
		box-shadow: 0 8px 32px rgba(get-color(dark, 1), .24);
	}
}

.testimonial-item-footer {
	padding-top: 24px;
}

@include media( '<=medium' ) {

	.testimonial {

		.tiles-item-inner {
			padding-left: 24px;
			padding-right: 24px;
		}
	}
}

@include media( '>=1145px' ) { // 1145px may need to e adjusted if testimonial items width or outer padding change

	.testimonial {

		.tiles-wrap {
			padding-bottom: 112px;
		}

		.tiles-item {
			position: relative;

			&:nth-child(3n+1) {
				top: 56px;
			}

			&:nth-child(3n+2) {
				top: 112px;
			}
		}
	}
}
