// Team (core/sections/_team.scss)
$team--padding-t__mobile: 		null;		// team, top padding (mobile)
$team--padding-b__mobile: 		null;		// team, bottom padding (mobile)
$team--padding-t__desktop: 		null;		// team, top padding (desktop)
$team--padding-b__desktop: 		null;		// team, bottom padding (desktop)
$team-header--padding__mobile: 	null;		// team header, bottom padding (mobile)
$team-header--padding__desktop:	80px;		// team header, bottom padding (desktop)
$team-items--padding__mobile: 	32px;		// padding between team items (mobile)
$team-items--padding__desktop: 	80px;		// padding between team items (desktop)
$team-item--width: 				296px;		// team item, width
$team-item--inner-padding-v: 	0;			// team item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$team-item--inner-padding-h: 	0;			// team item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
