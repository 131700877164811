.signin {

	.section-inner {
		padding-top: $signin--padding-t__mobile;
		padding-bottom: $signin--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $signin-header--padding__mobile;
	}

	.tiles-wrap {

		&.push-left {

			&::after {
				flex-basis: $signin-item--width;
				max-width: $signin-item--width;
			}
		}
	}

	.tiles-item {
		flex-basis: $signin-item--width;
		max-width: $signin-item--width;
	}

	.tiles-item-inner {
		padding-top: $signin-item--inner-padding-v;
		padding-bottom: $signin-item--inner-padding-v;
		padding-left: $signin-item--inner-padding-h;
		padding-right: $signin-item--inner-padding-h;
	}
}

@include media( '>medium' ) {

	.signin {

		.section-inner {
			padding-top: $signin--padding-t__desktop;
			padding-bottom: $signin--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $signin-header--padding__desktop;
		}
	}
}

.signin-footer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
