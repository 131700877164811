.hero {

	&.has-bg-color {
		position: relative;
		background-color: transparent;
		z-index: auto;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 640px;
			background-color: get-color(dark, 1);
			z-index: -3;
		}
	}

	&[class*=illustration-]::after {
		height: 640px;
	}
}
